export default class BaseComponent {
    constructor(domElement) {
        this.element = domElement;
    }

    getSiblings(elem) {
        let siblings = [],
        sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        return siblings;
    }
}