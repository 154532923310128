
import BaseComponent from './BaseComponent';



export default class getCarbonData extends BaseComponent {

	init() {
		this.getData();
	}
	getData(){

		const industryContribPercent = 0.38;
		const safeLevel = 350;

		const industryStatPlaceholder = document.getElementById('industry-stat');
		const aboveSafeCountPlaceholder = document.getElementById('above-safe-count');
		const aboveSafePercentPlaceholder = document.getElementById('above-safe-percent');

		let carbonCount = document.getElementById('carbon-count').innerHTML;

		let aboveSafeCount = carbonCount - safeLevel;


		// get Count from Building industry
		industryStatPlaceholder.innerHTML = (carbonCount * industryContribPercent).toFixed(2) + "<small>PPM</small>";

		// get above safe count
		aboveSafeCountPlaceholder.innerHTML = "+" + aboveSafeCount.toFixed(2) + "<small>PPM</small>";

		aboveSafePercentPlaceholder.innerHTML = "+" + (aboveSafeCount.toFixed(2) / safeLevel).toFixed(2) * 100 + "%" ;

	}

}