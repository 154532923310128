import { CountUp } from 'Node/countup.js' ;
import BaseComponent from './BaseComponent';



export default class startCounting extends BaseComponent {

	init() {
		this.initStartCount();
	}

	startCounting(ele, val) {

		// default options for counter
		let countOptions = {
			useEasing: true,
			// this number could change depending on where you want easing to stop
			smartEasingAmount: 315,
		};
		// adding decimal places - this is 2 because that's what
		// I needed for the project this class was created on 
		// but it should probably be dynamic
		let decimalPlaces ={
			decimalPlaces: 2
		}

		if( document.getElementById(ele).classList.contains('decimals') ){
			Object.assign(countOptions, decimalPlaces);
		}

		let eleAnim = new CountUp(ele, val, countOptions);

		if (!eleAnim.error) {
			  eleAnim.start();
		} else {
			  console.error(eleAnim.error);
		}


	}

	checkViewPort(element) {

		// let elementTop = element.offsetTop;
		// let elementBottom = elementTop + element.offsetHeight;
		let bounding = element.getBoundingClientRect();

		if (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
			bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
			) {
			return true
	} else {
		return false
	}

	return elementBottom > viewportTop && elementTop < viewportBottom;
}

initStartCount() {

	const counters = document.querySelectorAll('.count');
	const countersArray = Array.prototype.slice.call(counters);

	for (let counter in countersArray ){

		let countId = countersArray[counter].id;
		let countElement = document.getElementById(countId);
		let countVal = countElement.innerHTML;
		let animated = false;

		document.body.addEventListener( "scroll", () => {

			if ( this.checkViewPort( countElement )  && !animated ) {
				animated = true;
				this.startCounting( countId, countVal );
			}

		});
	}

}


}
