export default class ComponentLoader {
  constructor(components) {
    this.components = components;

  }

  load() { 
    let components = this.components;

    for (let selector in components) {
      let objects = document.querySelectorAll(selector);

      Array.from(objects).forEach((element) => {
        let component = new components[selector](element);
        component.init();
      });
    }
  }
}