
import startCounting from './components/_startCounting';
import getCarbonData from './components/_nzgbcGetCarbonData';

export const components = {

	'.count': startCounting,
	'#emmisions-counter-container' : getCarbonData, 

};
